import {gql} from '@apollo/client'

export const GET_ALL_QUOTES = gql`
  query allQuotes(
    $offset: Int
    $before: String
    $after: String
    $first: Int
    $last: Int
    $id: Float
    $created: DateTime
    $created_Gte: DateTime
    $created_Lte: DateTime
    $carrier: String
    $stateEU: String
    $typePolicy: ID
    $status: String
    $createdAfter: DateTime
    $createdBefore: DateTime
    $policyType: String
  ) {
    allQuotes(
      offset: $offset
      before: $before
      after: $after
      first: $first
      last: $last
      id: $id
      created: $created
      created_Gte: $created_Gte
      created_Lte: $created_Lte
      carrier: $carrier
      stateEU: $stateEU
      typePolicy: $typePolicy
      status: $status
      createdAfter: $createdAfter
      createdBefore: $createdBefore
      policyType: $policyType
    ) {
      pageInfo {
        hasNextPage
        endCursor
        startCursor
        hasPreviousPage
      }
      edges {
        node {
          id
          policyName
          applicants
          members
          dependents
          stateDocs
          paymentStatus
          FFM
          NPN
          typeSale
          effectiveDate
          marketplaceId
          memberId
          CMSPlanId
          planName
          metalLevel
          policyTotalCost
          taxCredit
          premium
          percentageValue
          nominalValue
          status
          user {
            id
            firstName
            lastName
            email
            cellphone
          }
          typePolicy {
            id
            typePolicy
          }
          carrier {
            id
            name
          }
          stateEU {
            name
            id
          }
          memberPolicySet {
            edges {
              node {
                id
                applicant
                firstName
                lastName
                phone
                rol
                physicalAddress
              }
            }
          }
          payment {
            id
            bankName
          }
        }
      }
    }
  }
`
