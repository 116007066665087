import {Dispatch, FC, SetStateAction, useState} from 'react'
import {Accordion, Alert, Button, Col, Form, Row} from 'react-bootstrap'
import {IForm, IPerson} from '../oneQuoteTypes'
import {useNavigate} from 'react-router-dom'
import {INIT_PEOPLE} from '../../../helpers/consts'

interface IAccorProps {
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void
  formData: IForm
  setFormData: Dispatch<SetStateAction<IForm>>
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  arrPeople: IPerson[]
  setArrPeople: Dispatch<SetStateAction<IPerson[]>>
  infoEnrollement: {
    id: null | number
    effectiveDate: string
    planId: string
    usesTobacco: boolean
  }
  setInfoEnrollement: Dispatch<
    SetStateAction<{
      id: null | number
      effectiveDate: string
      planId: string
      usesTobacco: boolean
    }>
  >
}

export const AccordionForm: FC<IAccorProps> = ({
  handleSubmit,
  formData,
  setFormData,
  handleChange,
  arrPeople,
  setArrPeople,
  infoEnrollement,
  setInfoEnrollement,
}) => {
  const navigate = useNavigate()
  const [eventKey, setEventKey] = useState('0')

  const handleAddPeople = () => {
    setEventKey(`${arrPeople.length + 1}`)
    setArrPeople((p) => [
      ...p,
      {
        id: Date.now(),
        ...INIT_PEOPLE,
      },
    ])
  }

  console.log('arrPeople', arrPeople)
  console.log('infoEnrollement', infoEnrollement)

  return (
    <Accordion>
      <Accordion.Item eventKey='0'>
        <Accordion.Header>Household Form</Accordion.Header>
        <Accordion.Body>
          <Form onSubmit={handleSubmit}>
            <div>
              <Alert variant='primary' className='my-5 d-flex align-items-center gap-2'>
                <span>
                  <i className='bi bi-exclamation-circle fs-1' style={{color: 'inherit'}} />
                </span>
                Please fill in the necessary information below to receive a quote for insurance
                plans.
              </Alert>

              {/* <Row className='mt-4'>
              <Col lg={3}>
                <Form.Group>
                  <Form.Label>Limit</Form.Label>
                  <Form.Control
                    type='number'
                    name='limit'
                    value={formData.limit}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Form.Group>
                  <Form.Label>Offset</Form.Label>
                  <Form.Control
                    type='number'
                    name='offset'
                    value={formData.offset}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row> */}

              <Row>
                <Col lg={3} md={6} sm={12} xs={12}>
                  <Form.Group>
                    <Form.Label>Household Income</Form.Label>
                    <Form.Control
                      type='text'
                      name='householdIncome'
                      value={formData.householdIncome}
                      onChange={(e) => {
                        const {value} = e.target
                        const noComma = value.replaceAll(',', '')
                        if (noComma !== '' && isNaN(Number(noComma))) return
                        const str = Intl.NumberFormat('en-US').format(Number(noComma))
                        setFormData((p) => ({...p, householdIncome: str}))
                      }}
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>
              <hr />
              <div className='mt-4'>
                <Accordion activeKey={eventKey} flush>
                  <Accordion.Item eventKey='0'>
                    <Accordion.Header onClick={() => setEventKey('0')}>Person 1</Accordion.Header>
                    <Accordion.Body>
                      <Row className='mt-4'>
                        <Col lg={4}>
                          <Form.Group>
                            <Form.Label>Date of Birth</Form.Label>
                            <Form.Control
                              type='date'
                              name='dob'
                              required={Boolean(!formData.personOne.age && !formData.personOne.dob)}
                              value={formData.personOne.dob}
                              onChange={(e) => {
                                if (e.target.value === '') {
                                  setFormData((p) => ({
                                    ...p,
                                    personOne: {...p.personOne, age: ''},
                                  }))
                                }
                                console.log('e.target.value', e.target.value)
                                const [yy] = e.target.value.split('-')
                                if (yy) {
                                  setFormData((p) => ({
                                    ...p,
                                    personOne: {
                                      ...p.personOne,
                                      age: `${new Date().getFullYear() - Number(yy)}`,
                                    },
                                  }))
                                }
                                setFormData((p) => ({
                                  ...p,
                                  personOne: {...p.personOne, dob: e.target.value},
                                }))
                              }}
                            />
                          </Form.Group>
                        </Col>

                        <Col lg={4}>
                          <Form.Group>
                            <Form.Label>Age</Form.Label>
                            <Form.Control
                              required={Boolean(!formData.personOne.age && !formData.personOne.dob)}
                              type='text'
                              name='Age'
                              disabled={Boolean(formData.personOne.dob)}
                              value={formData.personOne.age}
                              onChange={(e) => {
                                if (e.target.value === '') {
                                  setFormData((p) => ({
                                    ...p,
                                    personOne: {...p.personOne, age: ''},
                                  }))
                                }
                                if (isNaN(Number(e.target.value))) return
                                setFormData((p) => ({
                                  ...p,
                                  personOne: {...p.personOne, age: e.target.value},
                                }))
                              }}
                            />
                          </Form.Group>
                        </Col>

                        <Col lg={4}>
                          <Form.Group>
                            <Form.Label>Gender</Form.Label>
                            <Form.Control
                              required
                              as='select'
                              name='gender'
                              value={formData.personOne.gender}
                              onChange={(e) => {
                                setFormData((p) => ({
                                  ...p,
                                  personOne: {...p.personOne, gender: e.target.value},
                                }))
                              }}
                            >
                              <option value='Male'>Male</option>
                              <option value='Female'>Female</option>
                              <option value='Other'>Other</option>
                            </Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className='mt-4'>
                        <Col lg={4} md={6} sm={12} xs={12}>
                          <Form.Group className='d-flex'>
                            <Form.Check
                              type='switch'
                              // label='Is Pregnant'
                              name='isPregnant'
                              id='isPregnant'
                              checked={formData.personOne.isPregnant}
                              onChange={(e) => {
                                setFormData((p) => ({
                                  ...p,
                                  personOne: {...p.personOne, isPregnant: e.target.checked},
                                }))
                              }}
                            />
                            <Form.Label htmlFor='isPregnant'>
                              Is Pregnant ({formData.personOne.isPregnant ? 'YES' : 'NO'})
                            </Form.Label>
                          </Form.Group>
                        </Col>
                        <Col lg={4} md={6} sm={12} xs={12}>
                          <Form.Group className='d-flex'>
                            <Form.Check
                              type='switch'
                              // label='Is Pregnant'
                              name='isParent'
                              id='isParent'
                              checked={formData.personOne.isParent}
                              onChange={(e) => {
                                setFormData((p) => ({
                                  ...p,
                                  personOne: {...p.personOne, isParent: e.target.checked},
                                }))
                              }}
                            />
                            <Form.Label htmlFor='isParent'>
                              Is Parent ({formData.personOne.isParent ? 'YES' : 'NO'})
                            </Form.Label>
                          </Form.Group>
                        </Col>
                        <Col lg={4} md={6} sm={12} xs={12}>
                          <Form.Group className='d-flex'>
                            <Form.Check
                              type='switch'
                              // label='Is Pregnant'
                              name='aptcEligible'
                              id='aptcEligible'
                              checked={formData.personOne.aptcEligible}
                              onChange={(e) => {
                                setFormData((p) => ({
                                  ...p,
                                  personOne: {...p.personOne, aptcEligible: e.target.checked},
                                }))
                              }}
                            />
                            <Form.Label htmlFor='aptcEligible'>
                              APTC Eligible ({formData.personOne.aptcEligible ? 'YES' : 'NO'})
                            </Form.Label>
                          </Form.Group>
                        </Col>
                        <Col lg={4} md={6} sm={12} xs={12}>
                          <Form.Group className='d-flex'>
                            <Form.Check
                              type='switch'
                              // label='Is Pregnant'
                              name='usesTobacco'
                              id='usesTobacco'
                              checked={formData.personOne.usesTobacco}
                              onChange={(e) => {
                                setFormData((p) => ({
                                  ...p,
                                  personOne: {...p.personOne, usesTobacco: e.target.checked},
                                }))
                              }}
                            />
                            <Form.Label htmlFor='usesTobacco'>
                              Uses Tobacco ({formData.personOne.usesTobacco ? 'YES' : 'NO'})
                            </Form.Label>
                          </Form.Group>
                        </Col>
                        {/* <Col lg={4} md={6} sm={12} xs={12}>
                                  <Form.Group className='d-flex'>
                                    <Form.Check
                                      type='switch'
                                      // label='Is Pregnant'
                                      name='hasMarriedCouple'
                                      id='hasMarriedCouple'
                                      checked={formData.personOne.hasMarriedCouple}
                                      onChange={handleChange}
                                    />
                                    <Form.Label htmlFor='hasMarriedCouple'>
                                      Has Married Couple ({formData.personOne.hasMarriedCouple ? 'YES' : 'NO'})
                                    </Form.Label>
                                  </Form.Group>
                                </Col> */}
                        <Col lg={4} md={6} sm={12} xs={12}>
                          <Form.Group className='d-flex'>
                            <Form.Check
                              type='switch'
                              // label='Is Pregnant'
                              name='hasMec'
                              id='hasMec'
                              checked={formData.personOne.hasMec}
                              onChange={(e) => {
                                setFormData((p) => ({
                                  ...p,
                                  personOne: {...p.personOne, hasMec: e.target.checked},
                                }))
                              }}
                            />
                            <Form.Label htmlFor='hasMec'>
                              Has MEC ({formData.personOne.hasMec ? 'YES' : 'NO'})
                            </Form.Label>
                          </Form.Group>
                        </Col>
                      </Row>
                      {infoEnrollement.id === 1 ? (
                        <>
                          <hr />
                          <div className='d-flex w-100 align-items-center justify-content-between'>
                            <small>Current enrollment</small>
                            <button
                              className='btn btn-reset'
                              title='Delete enrollment'
                              onClick={() => {
                                setInfoEnrollement({
                                  effectiveDate: '',
                                  id: null,
                                  planId: '',
                                  usesTobacco: false,
                                })
                              }}
                            >
                              <i className='bi bi-x fs-1' />
                            </button>
                          </div>
                          <Row className='mt-4'>
                            <Col lg={4}>
                              <Form.Group>
                                <Form.Label>Effective Date</Form.Label>
                                <Form.Control
                                  type='date'
                                  name='effectiveDate'
                                  value={infoEnrollement.effectiveDate}
                                  onChange={(e) =>
                                    setInfoEnrollement((p) => ({
                                      ...p,
                                      effectiveDate: e.target.value,
                                    }))
                                  }
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={4}>
                              <Form.Group>
                                <Form.Label>Plan ID</Form.Label>
                                <Form.Control
                                  name='planId'
                                  value={infoEnrollement.planId}
                                  onChange={(e) =>
                                    setInfoEnrollement((p) => ({...p, planId: e.target.value}))
                                  }
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={4}>
                              <Form.Group
                                className='d-flex mt-4'
                                style={{justifySelf: 'end', alignSelf: 'flex-end'}}
                              >
                                <Form.Check
                                  type='switch'
                                  // label='Is Pregnant'
                                  name='usesTobacco'
                                  id='usesTobacco'
                                  checked={infoEnrollement.usesTobacco}
                                  onChange={(e) =>
                                    setInfoEnrollement((p) => ({
                                      ...p,
                                      usesTobacco: e.target.checked,
                                    }))
                                  }
                                />
                                <Form.Label htmlFor='isPregnant'>
                                  Use Tobacco ({infoEnrollement.usesTobacco ? 'YES' : 'NO'})
                                </Form.Label>
                              </Form.Group>
                            </Col>
                          </Row>
                        </>
                      ) : (
                        <div className='d-flex align-items-end w-100 justify-content-end'>
                          <Button
                            size='sm'
                            variant='danger'
                            style={{
                              alignSelf: 'end',
                            }}
                            className='d-flex px-2 py-1'
                            onClick={() => {
                              setInfoEnrollement({
                                effectiveDate: '',
                                id: 1,
                                planId: '',
                                usesTobacco: false,
                              })
                            }}
                          >
                            Enrollment
                          </Button>
                        </div>
                      )}
                    </Accordion.Body>
                  </Accordion.Item>
                  {arrPeople.map((inf, idx) => (
                    <Accordion.Item key={inf.id} eventKey={`${idx + 1}`}>
                      <Accordion.Header onClick={() => setEventKey(`${idx + 1}`)}>
                        Person {idx + 2}
                      </Accordion.Header>
                      <Accordion.Body>
                        <Row className='mt-4'>
                          <Col lg={4}>
                            <Form.Group>
                              <Form.Label>Date of Birth</Form.Label>
                              <Form.Control
                                type='date'
                                name='dob'
                                required={Boolean(!inf.age && !inf.dob)}
                                value={inf.dob}
                                onChange={(e) => {
                                  const {value} = e.target

                                  const [yy] = value.split('-')
                                  if (yy) {
                                    setArrPeople((prevPeople) =>
                                      prevPeople.map((person) =>
                                        person.id === inf.id
                                          ? {
                                              ...person,
                                              age: `${new Date().getFullYear() - Number(yy)}`,
                                            }
                                          : person
                                      )
                                    )
                                  }

                                  setArrPeople((prevPeople) =>
                                    prevPeople.map((person) =>
                                      person.id === inf.id ? {...person, dob: value} : person
                                    )
                                  )
                                }}
                              />
                            </Form.Group>
                          </Col>

                          <Col lg={4}>
                            <Form.Group>
                              <Form.Label>Age</Form.Label>
                              <Form.Control
                                type='text'
                                name='Age'
                                required={Boolean(!inf.age && !inf.dob)}
                                disabled={Boolean(inf.dob)}
                                value={inf.age}
                                onChange={(e) => {
                                  const {value} = e.target

                                  if (value === '') {
                                    setArrPeople((prevPeople) =>
                                      prevPeople.map((person) =>
                                        person.id === inf.id ? {...person, age: ''} : person
                                      )
                                    )
                                  }
                                  if (isNaN(Number(value))) return

                                  setArrPeople((prevPeople) =>
                                    prevPeople.map((person) =>
                                      person.id === inf.id ? {...person, age: value} : person
                                    )
                                  )
                                }}
                              />
                            </Form.Group>
                          </Col>

                          <Col lg={4}>
                            <Form.Group>
                              <Form.Label>Gender</Form.Label>
                              <Form.Control
                                required
                                as='select'
                                name='gender'
                                value={inf.gender}
                                onChange={(e) => {
                                  const {value} = e.target
                                  setArrPeople((prevPeople) =>
                                    prevPeople.map((person) =>
                                      person.id === inf.id ? {...person, gender: value} : person
                                    )
                                  )
                                }}
                              >
                                <option value='Male'>Male</option>
                                <option value='Female'>Female</option>
                                <option value='Other'>Other</option>
                              </Form.Control>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row className='mt-4'>
                          <Col lg={4} md={6} sm={12} xs={12}>
                            <Form.Group className='d-flex'>
                              <Form.Check
                                type='switch'
                                // label='Is Pregnant'
                                name='isPregnant'
                                id='isPregnant'
                                checked={inf.isPregnant}
                                onChange={(e) => {
                                  const {checked} = e.target
                                  setArrPeople((prevPeople) =>
                                    prevPeople.map((person) =>
                                      person.id === inf.id
                                        ? {...person, isPregnant: checked}
                                        : person
                                    )
                                  )
                                }}
                              />
                              <Form.Label htmlFor='isPregnant'>
                                Is Pregnant ({inf.isPregnant ? 'YES' : 'NO'})
                              </Form.Label>
                            </Form.Group>
                          </Col>
                          <Col lg={4} md={6} sm={12} xs={12}>
                            <Form.Group className='d-flex'>
                              <Form.Check
                                type='switch'
                                // label='Is Pregnant'
                                name='isParent'
                                id='isParent'
                                checked={inf.isParent}
                                onChange={(e) => {
                                  const {checked} = e.target
                                  setArrPeople((prevPeople) =>
                                    prevPeople.map((person) =>
                                      person.id === inf.id ? {...person, isParent: checked} : person
                                    )
                                  )
                                }}
                              />
                              <Form.Label htmlFor='isParent'>
                                Is Parent ({inf.isParent ? 'YES' : 'NO'})
                              </Form.Label>
                            </Form.Group>
                          </Col>
                          <Col lg={4} md={6} sm={12} xs={12}>
                            <Form.Group className='d-flex'>
                              <Form.Check
                                type='switch'
                                // label='Is Pregnant'
                                name='aptcEligible'
                                id='aptcEligible'
                                checked={inf.aptcEligible}
                                onChange={(e) => {
                                  const {checked} = e.target
                                  setArrPeople((prevPeople) =>
                                    prevPeople.map((person) =>
                                      person.id === inf.id
                                        ? {...person, aptcEligible: checked}
                                        : person
                                    )
                                  )
                                }}
                              />
                              <Form.Label htmlFor='aptcEligible'>
                                APTC Eligible ({inf.aptcEligible ? 'YES' : 'NO'})
                              </Form.Label>
                            </Form.Group>
                          </Col>
                          <Col lg={4} md={6} sm={12} xs={12}>
                            <Form.Group className='d-flex'>
                              <Form.Check
                                type='switch'
                                // label='Is Pregnant'
                                name='usesTobacco'
                                id='usesTobacco'
                                checked={inf.usesTobacco}
                                onChange={(e) => {
                                  const {checked} = e.target
                                  setArrPeople((prevPeople) =>
                                    prevPeople.map((person) =>
                                      person.id === inf.id
                                        ? {...person, usesTobacco: checked}
                                        : person
                                    )
                                  )
                                }}
                              />
                              <Form.Label htmlFor='usesTobacco'>
                                Uses Tobacco ({inf.usesTobacco ? 'YES' : 'NO'})
                              </Form.Label>
                            </Form.Group>
                          </Col>
                          {/* <Col lg={4} md={6} sm={12} xs={12}>
                                    <Form.Group className='d-flex'>
                                      <Form.Check
                                        type='switch'
                                        // label='Is Pregnant'
                                        name='hasMarriedCouple'
                                        id='hasMarriedCouple'
                                        checked={inf.hasMarriedCouple}
                                        onChange={handleChange}
                                      />
                                      <Form.Label htmlFor='hasMarriedCouple'>
                                        Has Married Couple ({inf.hasMarriedCouple ? 'YES' : 'NO'})
                                      </Form.Label>
                                    </Form.Group>
                                  </Col> */}
                          <Col lg={4} md={6} sm={12} xs={12}>
                            <Form.Group className='d-flex'>
                              <Form.Check
                                type='switch'
                                // label='Is Pregnant'
                                name='hasMec'
                                id='hasMec'
                                checked={inf.hasMec}
                                onChange={(e) => {
                                  const {checked} = e.target
                                  setArrPeople((prevPeople) =>
                                    prevPeople.map((person) =>
                                      person.id === inf.id ? {...person, hasMec: checked} : person
                                    )
                                  )
                                }}
                              />
                              <Form.Label htmlFor='hasMec'>
                                Has MEC ({inf.hasMec ? 'YES' : 'NO'})
                              </Form.Label>
                            </Form.Group>
                          </Col>
                        </Row>
                        {infoEnrollement.id === inf.id ? (
                          <>
                            <hr />
                            <div className='d-flex w-100 align-items-center justify-content-between'>
                              <small>Current enrollment</small>
                              <button
                                className='btn btn-reset'
                                title='Delete enrollment'
                                onClick={() => {
                                  setInfoEnrollement({
                                    effectiveDate: '',
                                    id: null,
                                    planId: '',
                                    usesTobacco: false,
                                  })
                                }}
                              >
                                <i className='bi bi-x fs-1' />
                              </button>
                            </div>
                            <Row className='mt-4'>
                              <Col lg={4}>
                                <Form.Group>
                                  <Form.Label>Effective Date</Form.Label>
                                  <Form.Control
                                    type='date'
                                    name='effectiveDate'
                                    value={infoEnrollement.effectiveDate}
                                    onChange={(e) =>
                                      setInfoEnrollement((p) => ({
                                        ...p,
                                        effectiveDate: e.target.value,
                                      }))
                                    }
                                  />
                                </Form.Group>
                              </Col>
                              <Col lg={4}>
                                <Form.Group>
                                  <Form.Label>Plan ID</Form.Label>
                                  <Form.Control
                                    name='planId'
                                    value={infoEnrollement.planId}
                                    onChange={(e) =>
                                      setInfoEnrollement((p) => ({...p, planId: e.target.value}))
                                    }
                                  />
                                </Form.Group>
                              </Col>
                              <Col lg={4}>
                                <Form.Group
                                  className='d-flex mt-4'
                                  style={{justifySelf: 'end', alignSelf: 'flex-end'}}
                                >
                                  <Form.Check
                                    type='switch'
                                    // label='Is Pregnant'
                                    name='usesTobacco'
                                    id='usesTobacco'
                                    checked={infoEnrollement.usesTobacco}
                                    onChange={(e) =>
                                      setInfoEnrollement((p) => ({
                                        ...p,
                                        usesTobacco: e.target.checked,
                                      }))
                                    }
                                  />
                                  <Form.Label htmlFor='isPregnant'>
                                    Use Tobacco ({formData.personOne.isPregnant ? 'YES' : 'NO'})
                                  </Form.Label>
                                </Form.Group>
                              </Col>
                            </Row>
                          </>
                        ) : (
                          <div className='d-flex align-items-end w-100 justify-content-end'>
                            <Button
                              size='sm'
                              variant='danger'
                              style={{
                                alignSelf: 'end',
                              }}
                              className='d-flex px-2 py-1'
                              onClick={() => {
                                setInfoEnrollement({
                                  effectiveDate: '',
                                  id: inf.id,
                                  planId: '',
                                  usesTobacco: false,
                                })
                              }}
                            >
                              Enrollment
                            </Button>
                          </div>
                        )}
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
              </div>

              <div className='mt-2'>
                <Button size='sm' variant='info' type='button' onClick={handleAddPeople}>
                  Add person
                </Button>
              </div>

              <Row className='mt-4'>
                {/* <Col lg={4}>
                            <Form.Group>
                              <Form.Label>Market</Form.Label>
                              <Form.Control
                                as='select'
                                name='market'
                                value={formData.market}
                                onChange={handleChange}
                              >
                                <option value='Individual'>Individual</option>
                              </Form.Control>
                            </Form.Group>
                          </Col> */}
              </Row>
            </div>
            <div className='d-flex align-items-center justify-content-end my-5 gap-3'>
              <Button variant='secondary' type='button' onClick={() => navigate(-1)}>
                Back
              </Button>
              <Button variant='primary' type='submit'>
                Search
              </Button>
            </div>
          </Form>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  )
}
