export const parseId = (id: string | number) => {
  if (!id) return 0
  if (typeof id === 'number') return id
  if (Number(id)) return id
  const parse = window.atob(id)
  const idStr = parse.split(':')[1]
  return idStr
}

const COLORS = ['primary', 'success', 'info', 'warning', 'danger', 'dark'] as const
export const getColorMember = (idx: number) => {
  const i = idx % COLORS.length
  return COLORS[i]
}

export const handleUpdateApp = (onLoading: (bool: boolean) => void) => {
  if ('serviceWorker' in window.navigator) {
    onLoading(true)
    navigator.serviceWorker.getRegistrations().then((registrations) => {
      // eslint-disable-next-line no-restricted-syntax
      for (const registration of registrations) {
        registration.unregister()
      }
      // eslint-disable-next-line no-undef
      caches.keys().then((kl) => {
        kl.forEach((key) => {
          // eslint-disable-next-line no-undef
          caches.delete(key)
        })
        /* NAMES_LOCALSTORAGE.forEach((name) =>
          window.localStorage.removeItem(name)
        ) */
        onLoading(false)
        window.location.reload()
      })
    })
  }
}

export const validateJsonQuote = (objeto: {[key: string]: any}) => {
  // Verificar si todas las propiedades requeridas están presentes y tienen el tipo de dato correcto
  if (
    typeof objeto.queryType !== 'string' ||
    typeof objeto.year !== 'number' ||
    typeof objeto.householdIncome !== 'string' ||
    // typeof objeto.aptcEligible !== 'boolean' ||
    // typeof objeto.hasMec !== 'boolean' ||
    // typeof objeto.dob !== 'string' ||
    // typeof objeto.isPregnant !== 'boolean' ||
    // typeof objeto.isParent !== 'boolean' ||
    // typeof objeto.usesTobacco !== 'boolean' ||
    // typeof objeto.gender !== 'string' ||
    // typeof objeto.hasMarriedCouple !== 'boolean' ||
    typeof objeto.market !== 'string' ||
    typeof objeto.countyfips !== 'string' ||
    typeof objeto.state !== 'string' ||
    typeof objeto.zipCode !== 'string' ||
    typeof objeto.limit !== 'number' ||
    typeof objeto.offset !== 'number' ||
    typeof objeto.order !== 'string' ||
    typeof objeto.utilizationLevel !== 'string' ||
    !Array.isArray(objeto.people)
  ) {
    return false
  }

  return true
}

export const getColorRamdomBoots = (n: number) => {
  const colors = ['primary', 'info', 'warning', 'error', 'secondary']
  return colors[n % colors.length] || 'dark'
}

export const parsePrice = (price: string) => {
  const num = !price || !Number(price) ? 0 : Number(price)
  return new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(num)
}

export const hasPermission = (permissions: string[], permission: string) => {
  return permissions.some((per) => permission === per)
}

export const formatPhoneNumber = (phoneNumberString: string) => {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '')
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    const intlCode = match[1] ? '+1 ' : ''
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
  }
  return phoneNumberString
}

export const getNameAvatar = (objStr: string) => {
  try {
    const obj = JSON.parse(objStr) as {name: string}
    console.log('---------->', obj)
    if (!obj.name) return 'NN'

    const arr = obj.name.split(' ')

    if (arr.length === 1) {
      return `${arr[0][0]}${arr[0][1]}`
    }
    if (arr.length === 2) {
      return `${arr[0][0]}${arr[1][0]}`
    }
    return `${arr[0][0]}${arr[2][0]}`
  } catch (_) {
    return 'NN'
  }
}
